
import {
    useLocation,
    useParams
} from 'react-router-dom';

import {
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';

import { LoadingOutlined, LockOutlined } from '@ant-design/icons';
import { Button as AntdButton, Input as AntdInput, Modal as AntdModal } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Alert, Button as BTN, Card, CardFooter, CardHeader } from 'reactstrap';

import Modal from '../../utils/Modal';

import Box from '../components/Box';
import Button from '../components/Button';
import Check from '../components/Check';
import Drop from '../components/Drop';
import Image from '../components/Image';
import Input from '../components/Input';
import Label from '../components/Label';
import List from '../components/List';
import Radio from '../components/Radio';

import { message } from "antd";
import FormService from '../../../../redux/services/forms';
import InputTable from '../components/InputTable';
import Signature from '../components/Signature';
import Snapshot from '../components/Snapshot';
import execute_script from '../components/execute_script';

const CreateRecord = (props) => {
    const location = useLocation();
    const history = useHistory();
    const { formId, recordId } = useParams();
    const [messageApi, contextHolder] = message.useMessage();
    // const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dbId, setDbId] = useState('');

    const [passwordModalVisible, setPasswordModalVisible] = useState(false);
    const [inputPassword, setInputPassword] = useState('');

    const mode = (props?.user?.is_superuser || props.user.groups.indexOf("Branch Admin") > -1) ? "admin" : "user"; // "admin"

    const [allowedPages, setAllowedPages] = useState([]); // for visibility
    const [editablePages, setEditablePages] = useState([]); // for editability
    const [selfEditablePages, setSelfEditablePages] = useState([]); // for editability


    const [recordOwner, setRecordOwner] = useState(-1);

    const [formName, setFormName] = useState('My Form');
    const [name, setName] = useState('New Record');
    const [editingName, setEditingName] = useState(false);

    const [errorDialog, setErrorDialog] = useState(false);


    // Array of components to be rendered
    const [components, setComponents] = useState([]);

    // States for pages
    const [pages, setPages] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);
    const [pageReady, setPageReady] = useState(false);

    const [vars, setVars] = useState([]);
    const [externals, setExternals] = useState({
        flags: [
            {
                name: "Loading Flags...",
                values: ['Loading Flags...'],
                colors: ['#fff'],
                value: 0
            }
        ]
    });

    const [errorsRender, setErrorsRender] = useState("");
    const [warningsRender, setWarningsRender] = useState("");

    const [isEditingFlags, setIsEditingFlags] = useState(false);
    const [currentFlag, setCurrentFlag] = useState(0);
    const [viewCurrentFlag, setViewCurrentFlag] = useState(false);
    const message_key = 'updatable';

    const saveButton = useRef();
    // handle what happens on key press
    const handleKeyPress = useCallback((event) => {
        if ((event.ctrlKey === true || event.metaKey === true) && event.key == 'Enter') {
            saveButton.current.click();
        }
        // if (event.shiftKey === true && event.key == 'S') {
        //     saveButton.current.click();
        // }
    }, []);
    useEffect(() => {
        // attach the event listener
        document.addEventListener('keydown', handleKeyPress);

        // remove the event listener
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const updateRecord = (closePage) => {
        if (recordId == null || recordId == undefined || recordId == '') {
            return;
        }
        messageApi.open({
            key: message_key,
            type: 'loading',
            content: 'Saving...',
        });

        FormService.updateRecord(
            recordId,
            {
                name: name,
                datetime: timestamp,
                form: formId,
                content: components,
                pages: pages,
                vars: vars,
                externals: externals,
                auth: auth
            }
        ).then(res => {
            messageApi.open({
                key: message_key,
                type: 'success',
                content: 'Record saved successfully.',
            });
            setLoading(false);
            if (closePage) {
                history.push(`/forms/${formId}/records`);
            }
        }).catch(res => {
            messageApi.open({
                key: message_key,
                type: 'error',
                content: 'Your record could not be saved.',
            });
            setLoading(false);
        });
    }



    let render_flags = [];
    let render_flag = [];
    try {
        for (let k = 0; k < externals.flags.length; ++k) {
            render_flags.push(
                <a style={{
                    width: '90%',
                    fontWeight: '300',
                    padding: '0.5vmax 0',
                    border: '1pt #faf3dd solid',
                    borderTop: k !== 0 ? '0pt' : '1pt #faf3dd solid'
                }} className='panel-button' onClick={(e) => {
                    e.preventDefault();
                    setCurrentFlag(k);
                    setViewCurrentFlag(true);
                }}>
                    {externals.flags[k].name}
                </a>
            );
        }
        for (let k = 0; k < externals.flags[currentFlag].values.length; ++k) {
            render_flag.push(
                <a style={{
                    width: '90%',
                    fontWeight: '300',
                    padding: '0.5vmax 0',
                    border: '1pt #faf3dd solid',
                    borderTop: k !== 0 ? '0pt' : '1pt #faf3dd solid',
                    backgroundColor: parseInt(externals.flags[currentFlag].value) === k ? '#faf3dd' : '#000',
                    color: parseInt(externals.flags[currentFlag].value) === k ? '#000' : '#faf3dd'
                }} className='panel-button' onClick={(e) => {
                    e.preventDefault();
                    let new_externals = { ...externals };
                    new_externals.flags[currentFlag].value = k;
                    setExternals(new_externals);
                }}>
                    {externals.flags[currentFlag].values[k]}
                </a>
            );
        }
    } catch (err) {
    }

    const [isSaving, setIsSaving] = useState(false);
    const [auth, setAuth] = useState({
        password: ''
    });

    const [timestamp, setTimestamp] = useState([
        new Date().getSeconds().toString().length === 1 ? '0' + new Date().getSeconds().toString() : new Date().getSeconds().toString(),
        new Date().getMinutes().toString().length === 1 ? '0' + new Date().getMinutes().toString() : new Date().getMinutes().toString(),
        new Date().getHours().toString().length === 1 ? '0' + new Date().getHours().toString() : new Date().getHours().toString(),
        new Date().getDate().toString().length === 1 ? '0' + new Date().getDate().toString() : new Date().getDate().toString(),
        new Date().getMonth().toString().length === 1 ? '0' + (new Date().getMonth() + 1).toString() : (new Date().getMonth() + 1).toString(),
        new Date().getFullYear().toString()
    ]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            let date = new Date();
            setTimestamp([
                date.getSeconds().toString().length === 1 ? '0' + date.getSeconds().toString() : date.getSeconds().toString(),
                date.getMinutes().toString().length === 1 ? '0' + date.getMinutes().toString() : date.getMinutes().toString(),
                date.getHours().toString().length === 1 ? '0' + date.getHours().toString() : date.getHours().toString(),
                date.getDate().toString().length === 1 ? '0' + date.getDate().toString() : date.getDate().toString(),
                date.getMonth().toString().length === 1 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString(),
                date.getFullYear().toString()
            ])
        }, 100);
        return () => clearInterval(intervalId);
    }, [timestamp]);

    const getFormPages = (record) => {
        FormService.getFormPages(formId).then(res => {
            setRecordOwner(record.created_by);
            setName(record.name);
            setAuth(record.auth);
            setComponents(record.content);
            setExternals(record.externals);
            setPages(record.pages);
            setVars(record.vars);

            setLoaded(true);
            setLoading(false);
            setPageReady(true);
            setEditablePages(res.editable_pages);
            setSelfEditablePages(res.self_editable_pages);
            setAllowedPages(res.allowed_pages);

            // let array1 = res.editable_pages.concat(res.self_editable_pages);
            // setEditablePages(array1);

            window.setTimeout(() => {
                setComponentsHaveRendered(false);
            }, 1000);
        });
    }

    useEffect(() => {
        setLoading(true);
        FormService.getFormDetail(formId).then(form => {

            if (recordId != null && recordId != undefined) {
                FormService.getRecord(recordId).then(res => {
                    if (res.record.auth.password != "" && props?.user.groups.includes('Branch Admin') == false) {
                        setPasswordModalVisible(true);
                    }
                    getFormPages(res.record);
                }).catch(err => {
                    if (err.request.status != 200) {
                        alert('Record not available');
                        history.push('/forms');
                    }
                });
            }
            else {
                setName(form?.name + ": New Record");
                FormService.getFormPages(formId).then(res => {
                    const _pages = []
                    const _contents = [];

                    res.data.map(item => {
                        item["page"]["page_id"] = item.page_id;
                        _pages.push(item["page"]);

                        item.content.map(content => {
                            content["page_id"] = item.page_id;
                            _contents.push(content);
                        });
                    });

                    setFormName(form.name);
                    setVars(form.vars);
                    setExternals(form.externals);
                    setDbId(form.form_id);
                    setComponents(_contents);
                    setPages(_pages);
                    setLoading(false);
                    setLoaded(true);
                    setPageReady(true);

                    setAllowedPages(res.allowed_pages);
                    let array1 = res.editable_pages.concat(res.self_editable_pages);
                    setEditablePages(array1);

                    window.setTimeout(() => {
                        setComponentsHaveRendered(false);
                    }, 1000);
                }).catch(err => {
                    setLoading(false);
                });
            }

        }).catch(err => {
            if (err.request.status != 200) {
                alert('Form not available');
                history.push('/forms');
            }
            setLoading(false);
        });

    }, []);



    const [componentsHaveRendered, setComponentsHaveRendered] = useState(false);
    const [componentsRender, setComponentsRender] = useState([]);



    useEffect(() => {
        for (let j = 0; j < components.length; ++j) {
            execute_script(0, j, components, setComponents, vars, setVars, externals, setExternals);
        }
        let new_errors_render = "";
        let new_warnings_render = "";
        let components_render = [];
        for (let k = 0; k < components.length; ++k) {

            if (editablePages.includes(components[k].page) || (selfEditablePages.includes(components[k].page) && recordOwner.id == props.user.id) || mode === "admin") {
                if (components[k].type !== 'intermediate/InputTable') {
                    if (components[k].error !== "") {
                        new_errors_render = `${new_errors_render}\nPage: ${components[k].page + 1}, ${components[k].name} (${k}): ${components[k].error}`;
                    }
                    if (components[k].hasOwnProperty('warning')) {
                        if (components[k].warning) {
                            new_warnings_render = `${new_warnings_render}\n${components[k].name} (${k}): ${components[k].warning}`;
                        }
                    }
                } else {
                    for (let p = 0; p < components[k].data.length; ++p) {
                        for (let q = 0; q < components[k].data[p].length; ++q) {
                            if (components[k].error[p][q] !== "") {
                                new_errors_render = `${new_errors_render}\nPage: ${components[k].page + 1}, ${components[k].name} (Row ${p + 1}, Column ${q + 1}): ${components[k].error[p][q]}`;
                            } if (components[k].warning[p][q] !== "") {
                                new_warnings_render = `${new_warnings_render}\nPage: ${components[k].page + 1}, ${components[k].name} (Row ${p + 1}, Column ${q + 1}): ${components[k].warning[p][q]}`;
                            }
                        }
                    }
                }
            }


            if (components[k].page === currentPage) {
                if (allowedPages.includes(currentPage) || mode === "admin") {
                    if (components[k].type === 'primitive/Box') {
                        components_render.push(
                            <Box key={`box_${k}`} id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'primitive/Label') {
                        components_render.push(
                            <Label key={`label_${k}`} id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'primitive/Button') {
                        components_render.push(
                            <Button key={`button_${k}`} id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'primitive/Input') {
                        components_render.push(
                            <Input key={`input_${k}`} id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'primitive/List') {
                        components_render.push(
                            <List key={`list_${k}`} id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'primitive/Check') {
                        components_render.push(
                            <Check key={`check_${k}`} id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'primitive/Radio') {
                        components_render.push(
                            <Radio key={`radio_${k}`} id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'primitive/Drop') {
                        components_render.push(
                            <Drop key={`drop_${k}`} id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'primitive/Image') {
                        components_render.push(
                            <Image key={`image_${k}`} id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'intermediate/Signature') {
                        components_render.push(
                            <Signature key={`signature_${k}`} id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'intermediate/InputTable') {
                        components_render.push(
                            <InputTable key={`input_table_${k}`} id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} currentPage={currentPage} editable={editablePages.includes(currentPage) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'intermediate/Snapshot') {
                        components_render.push(
                            <Snapshot key={`snapshot_${k}`} id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    }
                } else {
                    components_render = [<div style={{
                        width: '100%',
                        height: '20vh',
                        fontSize: '1.25vmax',
                        textAlign: 'center',
                        lineHeight: '20vh',
                        color: 'black'
                    }}>Access Prohibited</div>];
                }
            }
        }
        setComponentsRender(components_render);
        setComponentsHaveRendered(true);
        setErrorsRender(new_errors_render);
        setWarningsRender(new_warnings_render);
        setIsSaving(false);
    }, [pageReady, currentPage]);

    if (!componentsHaveRendered) {

        // const _components = components. 

        for (let j = 0; j < components.length; ++j) {
            execute_script(0, j, components, setComponents, vars, setVars, externals, setExternals);
        }

        let new_errors_render = "";
        let new_warnings_render = "";
        let components_render = [];



        for (let k = 0; k < components.length; ++k) {

            if (editablePages.includes(components[k].page) || (selfEditablePages.includes(components[k].page) && recordOwner.id == props.user.id) || mode === "admin") {
                // Above condition show errors for editable pages only
                // console.log('selfEditablePages1 > ', selfEditablePages);

                if (components[k].type !== 'intermediate/InputTable') {
                    if (components[k].error !== "") {
                        new_errors_render = `${new_errors_render}\nPage: ${components[k].page + 1}, ${components[k].name} (${k}): ${components[k].error}`;
                    }
                    if (components[k].hasOwnProperty('warning')) {
                        if (components[k].warning) {
                            new_warnings_render = `${new_warnings_render}\n${components[k].name} (${k}): ${components[k].warning}`;
                        }
                    }
                } else {
                    for (let p = 0; p < components[k].data.length; ++p) {
                        for (let q = 0; q < components[k].data[p].length; ++q) {
                            if (components[k].error[p][q] !== "") {
                                new_errors_render = `${new_errors_render}\nPage: ${components[k].page + 1}, ${components[k].name} (Row ${p + 1}, Column ${q + 1}): ${components[k].error[p][q]}`;
                            } if (components[k].warning[p][q] !== "") {
                                new_warnings_render = `${new_warnings_render}\nPage: ${components[k].page + 1},${components[k].name} (Row ${p + 1}, Column ${q + 1}): ${components[k].warning[p][q]}`;
                            }
                        }
                    }
                }
            }

            if (components[k].page === currentPage) {
                if (allowedPages.includes(currentPage) || mode === "admin") {
                    if (components[k].type === 'primitive/Box') {
                        components_render.push(
                            <Box id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || (selfEditablePages.includes(components[k].page) && recordOwner.id == props.user.id) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'primitive/Label') {
                        components_render.push(
                            <Label id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || (selfEditablePages.includes(components[k].page) && recordOwner.id == props.user.id) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'primitive/Button') {
                        components_render.push(
                            <Button id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || (selfEditablePages.includes(components[k].page) && recordOwner.id == props.user.id) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'primitive/Input') {
                        components_render.push(
                            <Input id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || (selfEditablePages.includes(components[k].page) && recordOwner.id == props.user.id) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'primitive/List') {
                        components_render.push(
                            <List id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || (selfEditablePages.includes(components[k].page) && recordOwner.id == props.user.id) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'primitive/Check') {
                        components_render.push(
                            <Check id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || (selfEditablePages.includes(components[k].page) && recordOwner.id == props.user.id) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'primitive/Radio') {
                        components_render.push(
                            <Radio id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || (selfEditablePages.includes(components[k].page) && recordOwner.id == props.user.id) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'primitive/Drop') {
                        components_render.push(
                            <Drop id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || (selfEditablePages.includes(components[k].page) && recordOwner.id == props.user.id) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'primitive/Image') {
                        components_render.push(
                            <Image id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || (selfEditablePages.includes(components[k].page) && recordOwner.id == props.user.id) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'intermediate/Signature') {
                        components_render.push(
                            <Signature id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || (selfEditablePages.includes(components[k].page) && recordOwner.id == props.user.id) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'intermediate/InputTable') {
                        components_render.push(
                            <InputTable id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} currentPage={currentPage} editable={editablePages.includes(currentPage) || (selfEditablePages.includes(components[k].page) && recordOwner.id == props.user.id) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    } else if (components[k].type === 'intermediate/Snapshot') {
                        components_render.push(
                            <Snapshot id={k} comps={components} setter={setComponents}
                                vw={0.95 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || (selfEditablePages.includes(components[k].page) && recordOwner.id == props.user.id) || mode === 'admin'} externals={externals} setExternals={setExternals} />
                        );
                    }
                } else {
                    components_render = [<div style={{
                        width: '100%',
                        height: '20vh',
                        fontSize: '1.25vmax',
                        textAlign: 'center',
                        lineHeight: '20vh',
                        color: 'black'
                    }}>Access Prohibited</div>];
                }
            }
        }
        setComponentsRender(components_render);
        setComponentsHaveRendered(true);
        setErrorsRender(new_errors_render);
        setWarningsRender(new_warnings_render);
        setIsSaving(false);
    }


    return (
        <div className='suman123'>
            {
                ((editablePages.includes(currentPage) == false && (selfEditablePages.includes(currentPage) == false && recordOwner.id != props.user.id) && mode != "admin")) ? (
                    <div style={{
                        'display': 'block',
                        'background': 'orange',
                        'font-weight': 'bold',
                        'text-align': 'center',
                        'color': '#fff'
                    }} > You don't have permission on this page.</div>
                ) : (
                    null
                )
            }

            {contextHolder}
            {
                loading ? (
                    <div className="blockUI">
                        <div className="loading-icon-container">
                            <LoadingOutlined />
                            <br />
                            <br />
                            <strong>Loading...</strong>
                        </div>
                    </div>
                ) : null
            }

            <AntdModal title={"Unlock This Record"} visible={passwordModalVisible} footer={[
                <AntdButton type="primary" onClick={(e) => {
                    if (inputPassword == auth.password) {
                        setPasswordModalVisible(false);
                    }
                    else {
                        alert('Invalid Password!');
                    }
                }}>Unlock</AntdButton>,
                <AntdButton type="danger" onClick={(e) => {
                    history.push(`/forms/${formId}/records`);
                }}>Close</AntdButton>
            ]}>
                <AntdInput placeholder='Enter your password' onChange={(e) => {
                    setInputPassword(e.target.value);
                }} prefix={<LockOutlined />} />
            </AntdModal>


            <Card style={{ width: '98%', margin: '10px auto' }}>
                <CardHeader>
                    <strong>{formName}</strong>

                    <span style={{ float: 'right' }}>
                        <b>Timestamp:</b> {timestamp[2]}:{timestamp[1]}:{timestamp[0]} <b>on</b> {timestamp[4]}/{timestamp[3]}/{timestamp[5]}
                    </span>
                </CardHeader>
            </Card>
            {/* <CardBody> */}
            <div onChange={(e) => { setComponentsHaveRendered(false); }} style={{ marginTop: 20 }}>
                <div id="wrapper" onScroll={(e) => {
                    setComponentsHaveRendered(false);
                }} style={{
                    position: 'absolute',
                    top: '135px',
                    left: '0',
                    width: '100%',
                    // margin: '0px auto',
                    // marginBottom: 100
                    height: '80vh',
                    // backgroundColor: '#fff',
                    overflow: 'auto',

                }}>
                    {
                        loaded ? <div className='abc' style={{
                            // background: '#ccc',
                            width: '95vw',
                            marginLeft: '2.5vw',
                            // width: pages[currentPage].width + "vw",
                            backgroundColor: '#fff',
                            // height: pages?.length > 0 && pages[currentPage]?.height ? (pages[currentPage]?.height * 70).toString() + 'vw' : '30vw'
                            height: (pages[currentPage].height * 95).toString() + "vw",
                            marginBottom: 150
                        }}>

                            {
                                componentsRender?.map((item, index) => {
                                    return <span title={`Element ${index}`}>
                                        {item}
                                    </span>
                                })
                            }
                        </div> : (
                            <div>Loading...</div>
                        )
                    }
                </div>
            </div>
            {/* </CardBody> */}
            <Card>
                <CardFooter>
                    <AntdModal title="Errors" visible={errorDialog} onOk={(e) => {
                        setErrorDialog(false)
                    }} onCancel={(e) => {
                        setErrorDialog(false)
                    }}>
                        <div>
                            {
                                errorsRender !== "" ? (
                                    <Alert color="danger" style={{ whiteSpace: "pre-wrap" }}>
                                        {errorsRender}
                                    </Alert>
                                ) : null
                            }
                            {
                                warningsRender !== "" ? (
                                    <Alert color="warning" style={{ whiteSpace: "pre-wrap" }}>
                                        <strong>Warning:</strong>
                                        {warningsRender}
                                    </Alert>
                                ) : null
                            }
                        </div>
                    </AntdModal>

                    <div style={{ position: 'fixed', bottom: 10, width: '100%' }}>
                        {/* {
                            errorsRender !== "" ? (
                                <Alert color="danger">
                                    <strong>Error:</strong>
                                    {errorsRender}
                                </Alert>
                            ) : null
                        }
                        {
                            warningsRender !== "" ? (
                                <Alert color="warning">
                                    <strong>Warning:</strong>
                                    {warningsRender}
                                </Alert>
                            ) : null
                        } */}

                        <Alert color="primary" style={{ marginBottom: 0 }}>
                            {/* <div>
                                {
                                    (errorsRender !== "" || warningsRender !== "") ? (
                                        <BTN color="danger" size="sm" onClick={(e) => {
                                            e.preventDefault();
                                            setErrorDialog(true);
                                        }}>
                                            This Page has some error(s). Click here to see.
                                        </BTN>
                                    ) : null
                                }
                            </div> */}
                            <div style={{ textAlign: 'center' }}>
                                <i className="fa-solid fa-chevron-left panel-icon" style={{
                                    fontSize: '1vmax',
                                    marginRight: '5vmax',
                                    padding: '0.45vmax 0.6vmax',
                                    borderRadius: '100px'
                                }} onClick={(e) => {
                                    if (currentPage !== 0) {
                                        setCurrentPage(currentPage - 1);
                                    } else {
                                        setCurrentPage(pages.length - 1);
                                    } setComponentsHaveRendered(false);
                                }}></i>
                                Page {currentPage + 1}/{pages.length}
                                <i className="fa-solid fa-chevron-right panel-icon" style={{
                                    fontSize: '1vmax',
                                    padding: '0.45vmax 0.6vmax',
                                    marginLeft: '5vmax',
                                    borderRadius: '100px'
                                }} onClick={(e) => {
                                    if (currentPage !== pages.length - 1) {
                                        setCurrentPage(currentPage + 1)
                                    } else {
                                        setCurrentPage(0);
                                    } setComponentsHaveRendered(false);
                                }}></i>
                            </div>

                            <div style={{ position: 'absolute', top: 15, right: 15 }}>
                                {
                                    (warningsRender !== "" && errorsRender === "") ? (
                                        <BTN color="warning" size="sm" onClick={(e) => {
                                            e.preventDefault();
                                            setErrorDialog(true);
                                        }}>
                                            This Page has some warning(s). Click here to see.
                                        </BTN>
                                    ) : (
                                        <></>
                                    )
                                }

                                {
                                    (errorsRender !== "") ? (
                                        <BTN color={errorsRender !== "" ? "danger" : "warning"} size="sm" onClick={(e) => {
                                            e.preventDefault();
                                            setErrorDialog(true);
                                        }}>
                                            This Page has some error(s)/warning(s). Click here to see.
                                        </BTN>
                                    ) : (

                                        location?.state?.mode != 'view' ? (
                                            <>

                                                <BTN color="primary" size="sm" onClick={(e) => {
                                                    e.preventDefault();
                                                    if (editablePages.length > 0 || selfEditablePages.length > 0 || mode === "admin") {
                                                        setIsEditingFlags(true);
                                                    }
                                                }}
                                                    disabled={editablePages.length <= 0 && selfEditablePages.length <= 0 && mode != "admin"}
                                                >
                                                    Edit Flags
                                                </BTN>
                                                &nbsp;&nbsp;
                                                <BTN color="primary" size="sm" disabled={editablePages.length <= 0 && selfEditablePages.length <= 0 && mode != "admin"} onClick={(e) => {
                                                    e.preventDefault();
                                                    if (editablePages.length > 0 || selfEditablePages.length > 0 || mode === "admin") {
                                                        setIsSaving(true);
                                                    }
                                                }}>
                                                    Save
                                                </BTN>
                                                <button ref={saveButton} style={{ display: 'none' }}
                                                    disabled={editablePages.length <= 0 && selfEditablePages.length <= 0 && mode != "admin"}
                                                    onClick={() => {
                                                        if (editablePages.length > 0 || selfEditablePages.length > 0 || mode === "admin") {

                                                            updateRecord(false);
                                                        }
                                                    }
                                                    }>Save</button>
                                            </>
                                        ) : null
                                    )
                                }

                                &nbsp;&nbsp;
                                <BTN color="danger" size="sm" onClick={(e) => {
                                    e.preventDefault();
                                    if (recordId != null && recordId != undefined) {
                                        // history.push(`/forms/${formId}/records`);
                                        history.goBack();
                                    }
                                    else {
                                        history.push(`/forms`);
                                    }

                                }}>
                                    Cancel
                                </BTN>

                            </div>
                        </Alert>
                    </div>
                </CardFooter >

            </Card >


            {
                isSaving && errorsRender === "" ? <Modal width='30' height='27.5'>
                    <h1 style={{
                        fontWeight: '300',
                        fontSize: '1.25vmax',
                        marginTop: '2vmax',
                        color: '#fff',
                    }}>Save Record</h1>
                    <p style={{
                        fontWeight: '300',
                        fontSize: '1vmax'
                    }}>
                        Record Name:

                        <input type="text" value={name} className='panel-input' onChange={(e) => {
                            setName(e.target.value);
                        }} />

                    </p>
                    <p style={{
                        fontWeight: '300',
                        fontSize: '1vmax'
                    }}>Timestamp: <b>{timestamp[2]}:{timestamp[1]}:{timestamp[0]}</b> on <b>{timestamp[4]}/{timestamp[3]}/{timestamp[5]}</b></p>
                    <p style={{
                        fontWeight: '300',
                        fontSize: '1vmax',
                        display: 'inline-block',
                        margin: '0',
                        marginBottom: '1.5vmax'
                    }}>Password: </p><input className='panel-input' style={{
                        margin: '0',
                        marginLeft: '0.5vmax'
                    }} placeholder='No Password' type={props?.user.groups.includes('Branch Admin') ? "text" : "password"} value={auth.password} onChange={(e) => {
                        setAuth({
                            password: e.target.value
                        });
                    }}></input>
                    <br />
                    <a className='panel-button' style={{
                        marginRight: '1vmax'
                    }} onClick={(e) => {
                        e.preventDefault();

                        if (loaded) {
                            setLoading(true);
                            for (let j = 0; j < components.length; ++j) {
                                execute_script(2, j, components, setComponents, vars, setVars, externals, setExternals);
                            }

                            if (recordId != null && recordId != undefined) {
                                updateRecord(true);
                            }
                            else {
                                FormService.createRecord({
                                    name: name,
                                    datetime: timestamp,
                                    form: formId,
                                    // creator: id,
                                    content: components,
                                    pages: pages,
                                    vars: vars,
                                    externals: externals,
                                    auth: auth
                                }).then(res => {
                                    setLoading(false);
                                    // history.push('/forms');
                                    history.push(`/forms/${formId}/records`);
                                }).catch(res => {
                                    setLoading(false);
                                })
                            }
                        }
                    }}>Save</a>
                    <a className='panel-button' onClick={(e) => {
                        e.preventDefault();
                        setIsSaving(false);
                    }}>Cancel</a>
                </Modal> : <>
                </>
            }
            {
                isEditingFlags ? <Modal width='40' height='50'>
                    <h1 style={{
                        color: '#FAF3DD',
                        fontWeight: '300',
                        margin: '1vmax 0',
                        padding: '1vmax 0',
                        fontSize: '1.75vmax',
                        display: 'block',
                        color: '#fff',
                    }}>Edit Flags</h1>
                    <div style={{
                        height: '65%',
                        overflow: 'scroll'
                    }}>
                        {render_flags}
                    </div>
                    <div style={{
                        position: 'absolute',
                        bottom: '0',
                        width: '100%',
                        backgroundColor: '#000000'
                    }}>
                        <a href='' className='panel-button' style={{
                            marginBottom: '1vmax'
                        }} onClick={(e) => {
                            e.preventDefault();
                            setIsEditingFlags(false);
                        }}>Okay</a>
                    </div>
                </Modal> : <></>
            }
            {
                viewCurrentFlag && loaded ? <Modal width='40' height='50'>
                    <h1 style={{
                        color: '#FAF3DD',
                        fontWeight: '300',
                        margin: '1vmax 0',
                        padding: '1vmax 0',
                        fontSize: '1.75vmax',
                        display: 'block',
                        color: '#fff',
                    }}>Edit {externals.flags[currentFlag].name}</h1>
                    <div style={{
                        height: '65%',
                        overflow: 'scroll'
                    }}>
                        {render_flag}
                    </div>
                    <div style={{
                        position: 'absolute',
                        bottom: '0',
                        width: '100%',
                        backgroundColor: '#000000'
                    }}>
                        <a href='' className='panel-button' style={{
                            marginBottom: '1vmax'
                        }} onClick={(e) => {
                            e.preventDefault();
                            setViewCurrentFlag(false);
                        }}>Okay</a>
                    </div>
                </Modal> : <></>
            }
        </div >
    );

}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user
    }
}
export default connect(mapStateToProps)(CreateRecord);